import gsap from 'gsap';
import $ from '../core/Dom';

const DEFAULT_DELAY = 0.2;

export default {
    init: () => {
        const intersectionCallback = (entries, obs) => {
            let delay = 0;
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const addedDelayMs = parseFloat(entry.target.getAttribute('data-reveal'));
                    delay += Number.isNaN(addedDelayMs) ? 0 : addedDelayMs;
                    gsap.to(entry.target, { duration: 1.75, delay, y: 0, ease: 'expo.out' });
                    gsap.to(entry.target, { duration: 0.5, delay, opacity: 1, ease: 'power2.inOut' });
                    obs.unobserve(entry.target);
                    delay += DEFAULT_DELAY;
                }
            });
        };

        const observer = new IntersectionObserver(intersectionCallback, {
            rootMargin: '0px 0px 10% 0px',
            threshold: 0
        });

        $('[data-reveal]').each(node => {
            observer.observe(node);
        });
    }
};
