import gsap from 'gsap';
import $ from '../core/Dom';

export default el => {
    const button = $(el).find('[aria-expanded]');
    const content = $(el).find('[data-content]').get(0);

    let expanded = false;

    const keyHandler = e => {
        const key = e.keyCode || e.which;
        if (expanded && key === 27) {
            close();
        }
    };

    const open = () => {
        if (!expanded) {
            button.attr('aria-expanded', 'true');
            gsap.set(content, { height: 'auto' });
            gsap.from(content, {
                height: 0,
                duration: 0.55,
                ease: 'power2.inOut',
                onComplete: () => {
                    gsap.set(content, { height: 'auto' });
                }
            });
            expanded = true;
            document.addEventListener('keyup', keyHandler);
        }
    };

    const close = () => {
        if (expanded) {
            button.attr('aria-expanded', 'false');
            gsap.to(content, { height: 0, duration: 0.55, ease: 'power2.inOut', clearProps: 'height' });
            expanded = false;
            document.removeEventListener('keyup', keyHandler);
        }
    };

    const toggle = () => {
        if (expanded) {
            close();
        } else {
            open();
        }
    };

    const init = () => {
        button.on('click', toggle);
    };

    const destroy = () => {
        button.off('click', toggle);
    };

    return {
        init,
        destroy
    };
};
