import $ from '../core/Dom';

export default class VideoLoop {
    constructor(el) {
        this.$el = $(el);

        this.$video = this.$el.find('[data-video]');
        this.video = this.$video.get(0);

        if (!this.video) {
            return;
        }

        this.intersecting = false;
        this.videoHasLoaded = false;
        this.videoIsPlaying = false;
        this.videoFallbackTimer = null;

        if (!this.video.canPlayType || !this.video.canPlayType('video/mp4')) {
            this.cantPlayVideo();
            return;
        }

        this.onLoadStart = this.onLoadStart.bind(this);
        this.onTimeUpdate = this.onTimeUpdate.bind(this);
        this.onFallbackTimer = this.onFallbackTimer.bind(this);
        this.onIntersectionChange = this.onIntersectionChange.bind(this);

        this.observer = new IntersectionObserver(this.onIntersectionChange, {
            threshold: 0,
            root: null
        });

        this.observer.observe(this.video);

        this.init();
    }

    onIntersectionChange(entries) {
        if (entries.filter(entry => entry.isIntersecting).length) {
            console.log('intersecting play');
            this.intersecting = true;
            this.playAndCatch();
        } else {
            console.log('not intersecting pause');
            this.videoIsPlaying = false;
            this.intersecting = false;
            this.video.pause();
        }
    }

    init() {

    }

    destroy() {
        this.killVideo();
        this.$el.removeClass('js-is-playing js-cant-play-video video-loop');
    }

    stopFallbackTimer() {
        if (!this.video || !this.videoFallbackTimer) {
            return;
        }
        clearTimeout(this.videoFallbackTimer);
        this.videoFallbackTimer = null;
    }

    startFallbackTimer(interval) {
        this.stopFallbackTimer();
        this.videoFallbackTimer = setTimeout(this.onFallbackTimer, interval);
    }

    addVideoEventListeners() {
        if (!this.video) {
            return;
        }
        this.video.addEventListener('timeupdate', this.onTimeUpdate);
        this.video.addEventListener('loadstart', this.onLoadStart);
        this.video.addEventListener('loadedmetadata', this.onLoadStart);
        this.video.addEventListener('loadeddata', this.onLoadStart);
        this.video.addEventListener('canplay', this.onLoadStart);
    }

    removeVideoEventListeners() {
        if (!this.video) {
            return;
        }
        this.video.removeEventListener('timeupdate', this.onTimeUpdate);
        this.video.removeEventListener('loadstart', this.onLoadStart);
        this.video.removeEventListener('loadedmetadata', this.onLoadStart);
        this.video.removeEventListener('loadeddata', this.onLoadStart);
        this.video.removeEventListener('canplay', this.onLoadStart);
    }

    killVideo() {
        this.stopFallbackTimer();
        this.removeVideoEventListeners();
        if (!this.video) {
            return;
        }
        this.video.pause();
        this.videoIsPlaying = false;
        this.videoHasLoaded = false;
    }

    playAndCatch() {
        if (!this.video || !this.intersecting) {
            return;
        }

        if (this.videoHasLoaded && !this.videoIsPlaying) {
            this.video.play();
            return;
        }

        this.addVideoEventListeners();
        this.video.muted = true;
        this.startFallbackTimer(1000);

        const promise = this.video.play();

        if (promise !== undefined) {
            promise.then(() => {
                console.log('playAndCatch.play');
                this.stopFallbackTimer();
            }).catch(e => {
                console.log(['playAndCatch.catch', e]);
                if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                    console.log('playAndCatch.catch: killing it');
                    this.cantPlayVideo();
                }
            });
        }
    }

    cantPlayVideo() {
        this.killVideo();
        this.$el.addClass('js-cant-play-video').removeClass('video-loop');
        this.$video.remove();
    }

    onFallbackTimer() {
        this.cantPlayVideo();
    }

    onVideoPlaying() {
        this.videoHasLoaded = true;
        this.videoIsPlaying = true;
        requestAnimationFrame(() => {
            this.$el.addClass('js-is-playing');
        });
    }

    onTimeUpdate() {
        if (!this.video || this.video.currentTime < 0.001) {
            return;
        }
        this.removeVideoEventListeners();
        this.stopFallbackTimer();
        this.onVideoPlaying();
    }

    onLoadStart() {
        if (this.videoIsPlaying) {
            return;
        }
        this.startFallbackTimer(6000);
    }
}
