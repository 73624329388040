import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';
import * as Events from '../lib/events';

export default el => {
    const dom = $(el);
    const button = dom.find('[aria-expanded]');
    const dialog = dom.find('[hidden]');
    const dialogEl = dialog.get(0);
    const content = dom.find('[data-content]');

    let active = false;
    let direction = 'right';

    const keyHandler = e => {
        const key = e.keyCode || e.which;
        if (active && key === 27) {
            Dispatch.emit(Events.HOTSPOT_CLOSE);
        }
    };

    const close = () => {
        if (active) {
            active = false;
            document.removeEventListener('keyup', keyHandler);
            dom.removeClass('js-is-active');
            button.attr('aria-expanded', false).attr('aria-label', button.data('label'));
            gsap.to(dialogEl, {
                duration: 0.15,
                opacity: 0,
                x: direction === 'left' ? -10 : 10,
                ease: 'quad.in',
                onComplete: () => {
                    dialog.attr('hidden', '').removeClass('left');
                }
            });
        }
    };

    const open = (key, target) => {
        if (target !== el && active) {
            close();
        } else if (target === el && !active) {
            active = true;
            document.addEventListener('keyup', keyHandler);
            dom.addClass('js-is-active');
            button.attr('aria-expanded', true).attr('aria-label', button.data('close'));
            dialog.attr('hidden', null);
            dialog.toggleClass('left', direction === 'left');
            gsap.set(dialogEl, { opacity: 0, x: direction === 'left' ? -20 : 20 });
            gsap.to(dialogEl, { duration: 0.15, opacity: 1, ease: 'sine.out' });
            gsap.to(dialogEl, { duration: 0.8, x: 0, ease: 'expo.out' });
        }
    };

    const toggle = e => {
        e.preventDefault();
        Dispatch.emit(button.attr('aria-expanded') === 'false' ? Events.HOTSPOT_OPEN : Events.HOTSPOT_CLOSE, el);
    };

    const resize = () => {
        direction = content.get(0).getBoundingClientRect().right > Viewport.width - 20 ? 'left' : 'right';
    };

    const init = () => {
        button.data('label', button.attr('aria-label'));
        button.on('click', toggle);
        Dispatch.on(Events.HOTSPOT_OPEN, open);
        Dispatch.on(Events.HOTSPOT_CLOSE, close);
        Viewport.on('resize', resize);
        resize();
    };

    const destroy = () => {
        button.off('click', toggle);
        Dispatch.off(Events.HOTSPOT_OPEN, open);
        Dispatch.off(Events.HOTSPOT_CLOSE, close);
        Viewport.off('resize', resize);
    };

    return {
        init,
        destroy
    };
};
